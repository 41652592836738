#content {
}

a {
    text-decoration:none;
    color: #7C89A6;
}

a:link {
  text-decoration: none;
  color: #7C89A6;
  font-weight:bold;
}

a:visited {
  background-color: transparent;
  text-decoration: none;
  color: #7C89A6;
  font-weight:bold;
}

#frame{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    margin:0;
    padding:0;
    overflow: hidden;
    box-sizing: border-box;
    border-style: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    background: #FFFFFF;
    width: 60vw;
    border-radius: 4px 0px 0px 4px;
    padding-left: 16px;
    color: #7C89A6;
    -moz-appearance:textfield;
}

input {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    top: 0px;
    -webkit-appearance: none;
    font-family: Source Sans Pro;
    font-size: 28px;
    font-weight: 600;
    height: 69px;
    border: none;
    line-height: 36px;
}

input[type=submit] {
    background: #5E49FD;
    color: #FFFFFF;
    border-radius: 0px 4px 4px 0px;
    width: 120px;
    cursor: pointer;
}

#secondRow {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #EA6969;
    text-align: left;
    margin-top: 15px;
}

#secondJoinRow {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #7C89A6;
    text-align: left;
    margin-top: 15px;
}

h3 {
    color: #FFFFFF;;
    text-align: left;
    font-weight: 600;
    margin-bottom: 34px;
    font-size: 35px;
}
