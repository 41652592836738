.App {
    text-align: center;
    background-color: #222C42;
}

.App-header {
    height: 66vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}
