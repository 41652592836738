#card {
    border: solid 5px transparent;
    border-radius: 10px;
    background-image: linear-gradient(#222C42, #222C42),
                      linear-gradient(to bottom, #FB9293, #EE739E);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 0px 0px 30px 10px rgba(11, 14, 21, 0.25);
}


#title {
    margin-top: 40px;
    margin-right: 20px;
    margin-bottom: 23px;
    margin-left: 20px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    color: #F27F98;
}

#msg {
    margin-top: 23px;
    margin-right: 20px;
    margin-bottom: 23px;
    margin-left: 20px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}
