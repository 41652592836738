#container {
    width: 335px;
}

#circle {
    display: flex;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    background-color: rgba(234, 105, 105, 0.2);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 23px 15px 23px 15px;
}

#thinking {
    width: 26px;
    height: 26px;
}

#logo {
    margin: 0px -13px 20px -13px;
}

#msgBox {
    box-shadow: 0px 0px 30px 10px rgba(11, 14, 21, 0.25);
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    justify-content: left;
    background-color: rgba(234, 105, 105, 0.2);
    border-radius: 7.18367px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #EA6969;
    margin-bottom: 20px;
}


#footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

#firstRow {
    display: flex;
    text-align: left;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

#appstore {
    height: 43px;
    max-width: 133px;
    padding-bottom: 20px;
    padding-top: 10px;
}

#googleplay{
    height: 43px;
    max-width: 152px;
    padding-bottom: 20px;
    padding-top: 10px;
}

#msgBox2 {
    box-shadow: 0px 0px 30px 10px rgba(11, 14, 21, 0.25);
    background-color: rgba(52, 190, 126, 0.2);
    border-radius: 7.18367px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #34BE7E;
}
